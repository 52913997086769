import Vue from 'vue'
import store from './store';
import Home from './views/Home.vue'
import './utils/rem.js'
import router from "./router";
import App from "./App.vue";
import xmlog from "./utils/log";// eslint-disable-line
import 'animate.css';

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);
Vue.config.productionTip = false
Vue.prototype.$xmlog = xmlog;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
