import { get, post } from "./http";

export const api = {
    init(v) {
        let d = {}
        if (v && v.length > 0) d['v'] = v
        return get('/init', d)
    },
    login(d) {
        return post('/code', d)
    },
    userInfo() {
        return get('/info', {})
    },
    codes() {
        return get('/codes', {})
    },
    fblike() {
        return get('/fblike', {})
    },
    store() {
        return get('/store', {})
    },
    share() {
        return get('/share', {})
    },
    joinGuild() {
        return get('/join_guild', {})
    },
    sendBarrage(s) {
        return post('/barrage', {text: s})
    },
    selCard(id) {
        return post('/sel_card', {id: id})
    },
    barrages() {
        return get('/barrages', {})
    },
    cards() {
        return get('/card_ratio', {})   
    },
    barrageOps(id, isLike) {
        return post('/barrage_ops', {id: id, ops: isLike ? 'like':'11'})   
        
    }
}