import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const user_key = 'user'
const store = new Vuex.Store({
    state: {
        userInfo: {
            id: '',
            code: ''
        }
    },
    mutations: {
        initStore(state) {
            let a = JSON.parse(localStorage.getItem(user_key))
            console.log('init->', a)
            if (a) {
                console.log('init2->', a)
                this.commit('loginLocal', a)
            }
        },
        loginLocal(state, payload) {
            console.log('loginLocal->', payload)
            state.userInfo = Object.assign(state.userInfo, payload)
            localStorage.setItem(user_key, JSON.stringify(state.userInfo));
            console.log('loginLocal2->', state.userInfo)
        },
        logout(state) {
            state.userInfo = {
                id: '',
                code: ''
            }
            localStorage.setItem(user_key, null);
        },
        getUInfo(state) {
            return state.userInfo;
        }
    }
})
export default store