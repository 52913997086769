/* eslint-disable */
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css';
import { api } from "../request/api.js"
import { mapState, mapMutations, mapGetters } from 'vuex'
import md5 from 'js-md5'


import Vue from 'vue'
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
    container: "body", //滚动的容器
    duration: 500, //滚动时间
    easing: "ease", //缓动类型
    offset: 0, //滚动时应应用的偏移量。此选项接受回调函数
    force: true, //是否应执行滚动
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});
export default {
    name: 'Home',
    components: {
        swiper,
        swiperSlide
    },
    created() {
        // console.log('------------------初始化阶段------------------')
        this.initStore()
        this.initBrowserLang()
        // this.initImg()
    },
    computed: {
        swiper2() {
            return this.$refs.mySwiper2.swiper
        },
        ...mapState(['userInfo'])
    },
    data() {
        return {
            dataIndex: 0,
            lastMenu: 0,
            people: 0,
            isAndroid: true,

            roles: [
                {
                    man: 1,
                    one: 1,
                },
                {
                    man: 1,
                    one: 1,
                },
                {
                    man: 1,
                    one: 1,
                },
                {
                    man: 1,
                    one: 1,
                }
            ],

            selRoleIndex: 0,
            swiperOption2: {
                autoplay: false,
                loop: false,
                on: {
                    slideChange: () => {
                        this.selRoleIndex = this.$refs.mySwiper2.swiper.activeIndex
                    },
                },
            },
            showMenu: 0,
            
            time: 0,
            showTime: 1,
            countdown: {
                d: "",
                h: "",
                m: "",
                s: "",
            },
            // codeStr: this.code,

            showTip: 0,
            showTip2: 0,
            showTip3: 0,
            showTipIndex: 'role0_m_zq_0',
            isEn: 0,
            isInit: 1,
            initP: 0
        }
    },
    mounted() {
        this.initData()
    },
    
    methods: {
        ...mapMutations(['loginLocal', 'initStore']),

        initData() {
            this.isAndroid = !!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            api.init().then(d => {
                this.people = d.people || 0
                this.time = Math.max(d.time, 0) || 0
                this.showTime && this.countDown()
                // console.error('initDat2a ->', d)
            }).catch(e => {
                console.error('initData ->', e)
            })
        },
        initBrowserLang() {
            let browserLang = navigator.language
                ? navigator.language
                : navigator.browserLanguage;
            if (
                browserLang.toLowerCase() === "us" ||
                browserLang.toLowerCase() === "en" ||
                browserLang.toLowerCase() === "en_us" ||
                browserLang.toLowerCase() === "en-us"
            ) {
                this.isEn = true
            } else {
                this.isEn = false
            }
            // this.isEn = true
            try{
                if (this.isEn) {
                    document.title = 'Fairy tale travel M--Heartwarming Fairytale MMO'
                }
            }catch(e) {

            }
            
            
        if (!this.isEn) {
            window.addEventListener('scroll', event => {
                let y = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                let ratio = document.getElementById('p1').width/750.0
                if (y <= 800 * ratio) {
                    this.lastMenu = 0;
                }else if (y <= 2200 * ratio) {
                    this.lastMenu = 1;
                }else if (y <= 3400 * ratio) {
                    this.lastMenu = 2;
                }else if (y <= 4500 * ratio) {
                    this.lastMenu = 3;
                }else{
                    this.lastMenu = 4;
                }
                // console.log(y);
              }, true);
        }
        },
        showZq(str) {
            this.showTipIndex = str
            this.showTip3 = true
        },
        async copyText(s, tip) {
            this.$copyText(s).then(
                e => {
                    console.log("copy arguments e:", e);
                    this.tip(tip)
                },
                e => {
                    console.log("copy arguments e:", e);
                    this.tip('copy error')
                })
        },
        tip(s) {
            alert(s)
        },
        selB(index) {
            if (this.dataIndex == index) return
            this.dataIndex = index
        },
        pre() {
            this.dataIndex = Math.max(this.dataIndex - 1, 0)
        },
        next() {
            this.dataIndex = Math.min(this.dataIndex + 1, 4)
        },
        toMenu(index) {
            this.lastMenu = index;
        },
        selRole(index) {
            if (this.selRoleIndex == index) return
            this.selRoleIndex = index
            this.$refs.mySwiper2.swiper.slideTo(index)
        },
        countDown() {
            // console.error('222')
            let times = this.time
            let t = times * 1000;
            // console.error('222->', t)
            if (t <= 0) {
                this.countdown.d = "";
                this.countdown.h = "";
                this.countdown.m = "";
                this.countdown.s = "";
                return;
            }
            let d = Math.floor(t / 1000 / 60 / 60 / 24) + "";
            this.countdown.d = d < 10 ? "0" + d : d + "";
            let h = Math.floor((t / 1000 / 60 / 60) % 24);
            this.countdown.h = h < 10 ? "0" + h : h + "";
            let m = Math.floor((t / 1000 / 60) % 60);
            this.countdown.m = m < 10 ? "0" + m : m + "";
            let s = Math.floor((t / 1000) % 60);
            this.countdown.s = s < 10 ? "0" + s : s + "";
            this.time = this.time - 1;

            setTimeout(() => {
                this.countDown();
            }, 1000);
        },
        openWeb(url) {
           setTimeout(()=>{
            window.open(url, '_blank')
           }, 10)
        },
        log(s) {
            console.log(s)
        },
        reqError(e) {
            this.tip(e.msg || 'unknown error')
        },
        openMenu() {
            this.log('openMenu')
            this.showMenu = !this.showMenu
        },
        isLogined() {
            console.error('u', this.userInfo)
            
            return this.userInfo.id.length > 0
        },
        toStore2() {
            if (!this.isAndroid) {
                alert('IOS暂未上线，敬请期待，关注官方FB粉丝页获取最新资讯！')
                this.toFb()
            }else{
                this.toStore()
            }
        },
        getUid() {
            let uid = '';
            if (this.isLogined()) {
                uid = this.userInfo.id;
            }else{
                uid = md5(Math.random().toString(36).substr(2,9) + '-'+Math.random().toString(36).substr(2,9) + '-' + new Date().getTime().toString(36)+'-'+Math.random().toString(36).substr(2,9));
            }
            console.error('uid', uid)
            return uid
        },
        toStore() {
            this.showTip = false
            this.log('toStore')
            // if (!this.isLogined()) {
            //     let uid = this.getUid()
            //     api.login({
            //         user_id: uid,
            //     }).then(d => {
            //         this.code = d.code || ''
            //         setTimeout(()=>{
            //             this.showTip = true
            //         }, 500)
            //         this.loginLocal({
            //             id: uid,
            //             code: this.code
            //         })
            //         this.$xmlog.register(()=> {
                        
            //         }, uid)
            //         this.$xmlog.clickStore(()=> {
            //             this.toGoogle()
            //         })
            //     }).catch(e => {
            //         alert(e.msg || e || 'error')
            //         this.$xmlog.clickStore(()=> {
            //             this.toGoogle()
            //         })
            //     })
            //     return
            // }
            this.$xmlog.clickStore(()=> {
                this.toGoogle()
            })
        },
        toGoogle() {
            this.openWeb(this.isEn ? 'https://play.google.com/store/apps/details?id=com.xmxjlr.guge&hl=en' : 'https://play.google.com/store/apps/details?id=com.xmxjlr.guge')   
        },
        hideMenu() {
            this.showMenu = false
        },
        toDc() {
            this.hideMenu()
            this.openWeb('https://discord.gg/U55pvgWfKm')
        },
        toPricaty() {
            // this.hideMenu()
            // this.openWeb('https://www.fairylandtraveler.com/privacy.html')
        },
        toNotice() {
            // this.hideMenu()
            // this.openWeb('https://www.fairylandtraveler.com/terms.html')
        },
        toTerms() {
            // this.hideMenu()
            // this.openWeb('https://www.fairylandtraveler.com/terms.html')
        },
        toFbEn() {
            this.log('toFbEn')
            this.$xmlog.fbLike(()=>{
                this.openWeb('https://www.facebook.com/fairytaletravelm/')
            })
        },
        toFb() {
            this.hideMenu()
            this.log('toFbLike')
            this.$xmlog.fbLike(()=>{
                this.openWeb('https://www.facebook.com/tonghuaqilv/')
            })
            
        },
        initImg() {
            var res = []
            const req = require.context('../assets', false, /\.(png|jpe?g|svg)$/);
            req.keys().forEach(key => {
                res.push(req(key))
            });
            this.loading(res, e => {
                this.initP = e
                if (e >= 100) {
                    this.isInit = true
                }
            })
        },
        loading(n, o) {
            var r = n.length
              , t = 0
              , a = 0
              , c = setInterval(function() {
                var n;
                r <= a ? (o && o(100, !0),
                clearInterval(c)) : a <= t && (a += 1,
                n = Math.floor(100 * a / r),
                o) && o(n, !1)
            }, 30);
            n.forEach(function(n) {
                var o = new Image;
                o.onload = function() {
                    t += 1
                }
                ,
                o.onerror = function() {
                    t += 1
                }
                ,
                o.src = n
            })
        }
    }
}