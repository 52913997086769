/*eslint-disable */
const xmlog = {
  log(eventName, cb) {
    this._fbLog('track', 'Lead')
    this._googleAdLog(eventName, null , cb)
  },
  register(cb, mobile) {//预约
    // setTimeout(cb, 300)
    // this._fbLog('track', 'CompleteRegistration')
    // this._tiktokLogRegister(mobile)
    // try{
    //   _lt('send', 'cv', {
    //     type: 'Conversion'
    //   },['c9ab91bd-7fb4-4b88-a734-8ff0ef98972d']);
    // }catch(e) {
    //   console.error(e)
    // }
    // this._googleAdLog('conversion', 'AW-16465807787/AqhMCPaDlJQZEKuTwas9',() => {
    //   this._googleAdLog('add_phone', null, () => {})  
    // })
  },
  clickStore(cb) {
    setTimeout(cb, 300)
    // console.error('vvv')
    this._fbLog('track', 'Lead')
    this._tiktokLogAndroid()
    // if (this._isIos()) {
    //   this._tiktokLogIOS()
    // }else{
    //   this._tiktokLogAndroid()
    // }
    
    // this._googleAdLog('conversion', 'AW-16465807787/ifZRCPmDlJQZEKuTwas9', () => {
    //   this._googleAdLog(this._isIos() ? 'iOS_Pre':'GP_Pre', null, ()=>{})
    //   console.error('zCK7bjJEYELfE2LMp --- ')
    //   this._googleAdLog('conversion', 'AW-11114258999/itKGCJTa95IYELfE2LMp', () => {
    //     console.error('itKGCJTa95IYELfE2LMp --- ')
         
    //   })
    // })
  },
  fbLike(cb) {
    setTimeout(cb, 500)
    this._googleAdLog('FB_like', null, ()=>{})  
  },
  btnLog(index, cb) {
    setTimeout(cb, 500)
    this._googleAdLog(['FB_page','FB_groud', 'Click_DC', 'Click_baha'][index], null, ()=>{}) 
  },
  _isIos() {
    let ua = navigator.userAgent;
    return /iphone|ipad|ipod/ig.test(ua);
  },
  _fbLog(a, b) {
    if (typeof fbq != 'undefined') {
      fbq(a, b)
    }
  },
  _tiktokLogRegister(mobile) {
    try{
      const PIXEL_CODE = 'CLVRQPBC77U0797C9NFG';
    // const EVENT_NAME = 'SubmitForm'
    const EVENT_NAME = 'CompleteRegistration'

    // Phone样例 正确格式：+886996123456  
    // 错误格式：+8860996123456 +886-996123456
    console.error(mobile)
    ttq.instance(PIXEL_CODE).identify({ phone_number: mobile })
    ttq.instance(PIXEL_CODE).track(EVENT_NAME)
    }catch(e) {

    }
  },
  _tiktokLogFbShare() {
    
  },
  _tiktokLogIOS() {
    const PIXEL_CODE = 'CLVRQPBC77U0797C9NFG';
    const EVENT_NAME = 'Download'
    const DEDUP_KEY = 'ios_btn_time' // You can define your de-dup key, e.g. session-id
    const DEDUP_VALUE_TIME = sessionStorage.getItem(DEDUP_KEY);
    const DEDUP_TIME_WINDOW = 1000 * 60 * 10; // 10 minutes, long enough

    if (DEDUP_VALUE_TIME === null) {
      /** Track for the first time */
      ttq.instance(PIXEL_CODE).track(EVENT_NAME);
      sessionStorage.setItem(DEDUP_KEY, Date.now());
    } else {
      try {
        if (Date.now() - DEDUP_VALUE_TIME > DEDUP_TIME_WINDOW) {
          /** Track for the second after tracking time window */
          ttq.instance(PIXEL_CODE).track(EVENT_NAME);
          sessionStorage.setItem(DEDUP_KEY, Date.now());
          console.log('longer than 10 min, send again')
        } else {
          /** Skip Tracking in short time window */
          console.log('Less than 10 min, skip sending')
        }
      } catch (err) {
        /** Unknown error, reset tracking and timing flag */
        ttq.instance(PIXEL_CODE).track(EVENT_NAME);
        sessionStorage.setItem(DEDUP_KEY, Date.now());
      }
    }
  },
  _tiktokLogAndroid() {
    const PIXEL_CODE = 'CLVRQPBC77U0797C9NFG';
    const EVENT_NAME = 'ClickButton'
    const DEDUP_KEY = 'and_btn_time' // You can define your de-dup key, e.g. session-id
    const DEDUP_VALUE_TIME = sessionStorage.getItem(DEDUP_KEY);
    const DEDUP_TIME_WINDOW = 1000 * 60 * 10; // 10 minutes, long enough

    if (DEDUP_VALUE_TIME === null) {
      /** Track for the first time */
      ttq.instance(PIXEL_CODE).track(EVENT_NAME);
      sessionStorage.setItem(DEDUP_KEY, Date.now());
    } else {
      try {
        if (Date.now() - DEDUP_VALUE_TIME > DEDUP_TIME_WINDOW) {
          /** Track for the second after tracking time window */
          ttq.instance(PIXEL_CODE).track(EVENT_NAME);
          sessionStorage.setItem(DEDUP_KEY, Date.now());
          console.log('longer than 10 min, send again')
        } else {
          /** Skip Tracking in short time window */
          console.log('Less than 10 min, skip sending')
        }
      } catch (err) {
        /** Unknown error, reset tracking and timing flag */
        ttq.instance(PIXEL_CODE).track(EVENT_NAME);
        sessionStorage.setItem(DEDUP_KEY, Date.now());
      }
    }
  },
  _lineLog() {
    
  },
  _googleAdLog(eventName, sendTo, cb) {
    try {
      if (typeof gtag != 'undefined') {
        var p = {
          event_callback: (event) => {
            cb && cb()
          }
        }
        if (sendTo && sendTo.length > 0) {
          p.send_to = sendTo
        }
        gtag('event', eventName, p)
      }
    } catch (error) {
      
    }
  }
}

export default xmlog